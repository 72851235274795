<template>
  <div>
    <!-- 正在获取用户信息页面 -->
    <div
      v-if="system_name == null"
      class="blue-grey lighten-5 px-6 py-16 d-flex flex-column align-center justify-space-between unselect"
      style="width:100vw;height:100vh"
    >
      <div class="d-flex flex-column align-center mt-6">
        <v-sheet
          color="white"
          elevation="2"
          height="120"
          width="120"
          class="rounded-circle"
        >
          <v-img
            contain
            width="120"
            height="120"
            class="rounded-circle"
            src="../../../assets/avatar.png"
          ></v-img>
        </v-sheet>
        <div class="black--text text-h6 text-center mt-11">
          <div>获取用户信息中</div>
        </div>
      </div>
    </div>
    <!-- 用户信息获取成功，待登录页面 -->
    <div
      v-else
      class="blue-grey lighten-5 px-6 py-12 d-flex flex-column align-center justify-space-between unselect"
      style="width:100vw;height:100vh"
    >
      <div class="d-flex flex-column align-center mt-6">
        <v-sheet
          color="white"
          elevation="2"
          height="120"
          width="120"
          class="rounded-circle"
        >
          <v-img
            contain
            width="120"
            height="120"
            class="rounded-circle"
            :src="headimgurl ? headimgurl : require('@/assets/avatar.png')"
          ></v-img>
        </v-sheet>
        <div class="black--text text-h6 text-center mt-2">
          <div>{{ nickname }}</div>
          <div class="mt-9">
            <div>您即将以此身份登录</div>
            <div>{{ system_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import Cookie from "js-cookie";
import { api_request } from "@/util/network";
import { parseQuery } from "@/util/util";
import { saveLastAccount } from "@/util/fido_util";

export default {
  name: "WechatCallback",
  data() {
    return {
      wait: true,
      id: null,
      headimgurl: null,
      nickname: "",
      identity_token: null,
      session_id: null,
      system_name: null
    };
  },
  async created() {
    await this.getSchoolName();
    await this.filterId();
    let needRebind = await sessionStorage.getItem("needRebind");
    if (needRebind) {
      this.sessionFinish();
    } else {
      this.finish();
    }
  },
  methods: {
    async getSchoolName() {
      return this.$http
        .get(`api/system_config`)
        .delegateTo(api_request)
        .then(({ system_name }) => {
          this.system_name = system_name ? system_name : "瑞智康诚WebEXP";
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async filterId() {
      return this.$http
        .get(`api/settings/mobile_login`)
        .delegateTo(api_request)
        .then(res => {
          let { qrcode } = res;
          this.id = qrcode;
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    finish() {
      if (this.id) {
        let urlQuery = location.search.substr(1);
        let queryData = parseQuery(urlQuery);
        if (queryData) {
          let { token, session, sign, time } = queryData;
          this.session_id = session;
          let credentials = {
            token,
            session_id: session,
            sign,
            timestamp: time
          };

          this.$http
            .post(`api/source/${this.id}/finish`, credentials)
            .delegateTo(api_request)
            .then(data => {
              if (data.identity_token) {
                let {
                  identity: {
                    claims: { nickname, headimgurl }
                  },
                  identity_token,
                  session_id
                } = data;
                this.headimgurl = headimgurl;
                this.nickname = nickname;

                this.identity_token = identity_token;

                let finishData = {
                  headimgurl,
                  nickname,
                  identity_token,
                  session_id
                };

                localStorage.setItem("finishData", JSON.stringify(finishData));

                let needRebind = sessionStorage.getItem("needRebind");
                if (!needRebind) {
                  sessionStorage.setItem("needBind", true);
                  sessionStorage.setItem("logoutStatus", true);
                  Cookie.remove("session_id");
                  this.$router.push("/login");
                } else {
                  sessionStorage.setItem("rebinding", true);
                  sessionStorage.setItem(
                    "bindInfo",
                    JSON.stringify({
                      headimgurl,
                      nickname,
                      identity_token,
                      session_id: session
                    })
                  );
                  Cookie.remove("session_id");
                  this.$router.push("/user/home");
                }
              } else if (data.auth_token) {
                Cookie.remove("session_id");
                this.$store.commit("auth_success", data);
                saveLastAccount(data);
                if (data["return_url"]) {
                  window.location = data["return_url"];
                } else {
                  this.$router.push({ path: "/" });
                }
              }
            })
            .catch(({ code, message }) => {
              throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                "api." + typeof message === "string"
                  ? message
                  : JSON.stringify(message)
              )}`;
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    sessionFinish() {
      if (this.id) {
        let urlQuery = location.search.substr(1);
        let queryData = parseQuery(urlQuery);
        if (queryData) {
          let { token, session, sign, time } = queryData;
          this.session_id = session;
          let credentials = {
            token,
            session_id: session,
            sign,
            timestamp: time
          };
          this.$http
            .post(`api/source/${this.id}/session_finish`, credentials)
            .delegateTo(api_request)
            .then(data => {
              if (data.identity_token) {
                let {
                  identity: {
                    claims: { nickname, headimgurl }
                  },
                  identity_token
                } = data;
                this.headimgurl = headimgurl;
                this.nickname = nickname;
                this.identity_token = identity_token;

                let needRebind = sessionStorage.getItem("needRebind");
                if (!needRebind) {
                  sessionStorage.setItem("needBind", true);
                  sessionStorage.setItem("logoutStatus", true);
                  Cookie.remove("session_id");
                  this.$router.push("/login");
                } else {
                  sessionStorage.setItem("rebinding", true);
                  sessionStorage.setItem(
                    "bindInfo",
                    JSON.stringify({
                      headimgurl,
                      nickname,
                      identity_token,
                      session_id: session
                    })
                  );
                  Cookie.remove("session_id");
                  this.$router.push("/user/home");
                }
              }
            })
            .catch(({ code, message }) => {
              throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                "api." + typeof message === "string"
                  ? message
                  : JSON.stringify(message)
              )}`;
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    }
  },
  components: {
    Snackbar
  }
};
</script>

<style lang="less" scoped>
.v-application .text-h6 {
  font-family: system-ui !important;
}
</style>
