<template>
  <v-app>
    <MobileCallBack v-if="mobileDevice === 'mobileDevice'"></MobileCallBack>

    <PcCallBack v-if="mobileDevice === 'pcDevice'"></PcCallBack>
  </v-app>
</template>

<script>
import Cookie from "js-cookie";
import PcCallBack from "./component/PcCallBack";
import MobileCallBack from "./component/MobileCallBack";

export default {
  name: "login",
  data() {
    return {
      mobileDevice: null
    };
  },
  async created() {
    let session_id = await Cookie.get("session_id");
    if (session_id) {
      this.mobileDevice = "mobileDevice";
    } else {
      this.mobileDevice = "pcDevice";
    }
  },
  components: {
    PcCallBack,
    MobileCallBack
  }
};
</script>
